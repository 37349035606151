// Generated by Framer (f1edf09)

import { addFonts, cx, CycleVariantState, getFonts, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import Slideshow from "https://framerusercontent.com/modules/zvkTOpMSuRzRhLzZZIwG/tMgLW02JctHcv25gtiqf/SlideShow.js";
const SlideshowFonts = getFonts(Slideshow);

const cycleOrder = ["butHAKxhI"];

const variantClassNames = {butHAKxhI: "framer-v-s906cn"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `perspective(1200px) ${t}`

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "butHAKxhI", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "butHAKxhI", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-qsfv3", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-s906cn", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"butHAKxhI"} ref={ref} style={{...style}} transition={transition}><motion.div className={"framer-e8a7vy"} layoutDependency={layoutDependency} layoutId={"MOP3HJtJ3"} transition={transition}><motion.div __perspectiveFX={false} __targetOpacity={1} className={"framer-6uj2dx-container"} layoutDependency={layoutDependency} layoutId={"nM25__nSc-container"} transformTemplate={transformTemplate} transition={transition}><Slideshow alignment={"center"} arrowOptions={{arrowFill: "rgba(0, 0, 0, 0.2)", arrowGap: 10, arrowPadding: 20, arrowPaddingBottom: 0, arrowPaddingLeft: 0, arrowPaddingRight: 0, arrowPaddingTop: 0, arrowPosition: "auto", arrowRadius: 40, arrowShouldFadeIn: false, arrowShouldSpace: true, arrowSize: 40, showMouseControls: true}} autoPlayControl borderRadius={0} direction={"left"} dragControl effectsOptions={{effectsOpacity: 1, effectsScale: 1, effectsPerspective: 1200, effectsRotate: 0, effectsHover: true}} fadeOptions={{fadeContent: false, overflow: true, fadeWidth: 25, fadeInset: 12, fadeAlpha: 0}} gap={20} height={"100%"} id={"nM25__nSc"} intervalControl={2} itemAmount={1} layoutId={"nM25__nSc"} padding={0} paddingBottom={0} paddingLeft={0} paddingPerSide paddingRight={0} paddingTop={40} progressOptions={{dotsActiveOpacity: 1, dotsBackground: "rgba(0, 0, 0, 0.2)", dotsBlur: 0, dotsFill: "rgb(255, 255, 255)", dotsGap: 10, dotsInset: 10, dotSize: 10, dotsOpacity: 0.5, dotsPadding: 10, dotsRadius: 50, showProgressDots: false}} slots={[]} startFrom={0} style={{height: "100%", width: "100%"}} transitionControl={{damping: 40, stiffness: 200, type: "spring"}} width={"100%"}/></motion.div></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-qsfv3 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-qsfv3 * { box-sizing: border-box; }", ".framer-qsfv3 .framer-1767xfb { display: block; }", ".framer-qsfv3 .framer-s906cn { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 100px; height: 376px; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 1000px; }", ".framer-qsfv3 .framer-e8a7vy { flex: 1 0 0px; height: 1px; max-height: 500px; overflow: visible; position: relative; width: 450px; }", ".framer-qsfv3 .framer-6uj2dx-container { flex: none; height: 100%; left: 0px; position: absolute; top: calc(50.00000000000002% - 100% / 2); width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-qsfv3 .framer-s906cn { gap: 0px; } .framer-qsfv3 .framer-s906cn > * { margin: 0px; margin-bottom: calc(100px / 2); margin-top: calc(100px / 2); } .framer-qsfv3 .framer-s906cn > :first-child { margin-top: 0px; } .framer-qsfv3 .framer-s906cn > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 376
 * @framerIntrinsicWidth 1000
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const Framerc6FR92c6x: React.ComponentType<Props> = withCSS(Component, css, "framer-qsfv3") as typeof Component;
export default Framerc6FR92c6x;

Framerc6FR92c6x.displayName = "SLIDER";

Framerc6FR92c6x.defaultProps = {height: 376, width: 1000};

addFonts(Framerc6FR92c6x, [...SlideshowFonts])